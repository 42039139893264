<template>
  <div class="mainform mainform-dilog">
    <div class="mainHeader mag-btm">
      xxxxx标题xxxxx
      <i class="feiqi">待我确认</i>
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form fbxj-table">
      <div class="xunjia-news">
        <span></span>基本信息
      </div>
      <div class="elrowflx">
        <div class="elwidth">
          <el-row :gutter="20">
            <el-col class="clearfix" :span="12">
              <div class="xjtit">通知单号</div>
              <div class="xjcont">200013792</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">公司名称</div>
              <div class="xjcont">中科曙光</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">单据类型</div>
              <div class="xjcont">来料批不合格</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">创建日期</div>
              <div class="xjcont">2021-05-08</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">备注</div>
              <div class="xjcont">相关备注信息</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">状态</div>
              <div class="xjcont">待我确认</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="xunjia-news">
        <span></span>质量对象
      </div>
      <div class="elrowflx">
        <div class="elwidth">
          <el-row :gutter="20">
            <el-col class="clearfix" :span="12">
              <div class="xjtit">供应商编码</div>
              <div class="xjcont">118118</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">供应商名称</div>
              <div class="xjcont">合肥艾瑞电子有限公司</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">物料编码</div>
              <div class="xjcont">240010009</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">物料描述</div>
              <div class="xjcont">TYAN S7050A2NRF主板</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">工厂</div>
              <div class="xjcont">1000公司生产工厂</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">物料类别</div>
              <div class="xjcont">主板</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="xunjia-news">
        <span></span>异常问题
      </div>
      <div class="elrowflx">
        <div class="elwidth">
          <el-row :gutter="20">
            <el-col class="clearfix" :span="24">
              <el-table class="table" :data="ApplyingList" style="width: 100%">
                <el-table-column label="序号" width="60px">
                  <template #default="scope">
                    {{scope.$index+1}}
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="qxfl" label="缺陷分类" width=""></el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="qxz" label="缺陷组" width=""></el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="qxdm" label="缺陷细类代码" width=""></el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="qxms" label="缺陷描述" width=""></el-table-column>>
                <el-table-column :show-overflow-tooltip="true" prop="blsl" label="不良数量" width=""></el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="sysl" label="使用数量" width=""></el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="bll" label="不良率" width=""></el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="wtlx" label="问题类型" width=""></el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
      </div>

    </div>
    <!-- 确认弹框 -->
    <div class="modify xbj-modify">
      <el-dialog title="提示" v-model="dialogModify" width="30%">
        <div class="role-cont">
          <el-form :model="bzData" ref="bzData" :rules="bzDataRules" class="demo-ruleForm">
            <el-form-item class="item1" label="" prop="bzsm">
              <div class="bzCont">来料+物料描述（物料编号）+缺陷描述</div>
              <el-input type="textarea" v-model="bzData.bzsm" placeholder="请填写备注内容"></el-input>
            </el-form-item>
            <el-form-item class="item2">
              <el-button size="mini" @click="dialogModify = false">取 消</el-button>
              <el-button size="mini" type="primary" @click="confirmModify('bzData')">确 认</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
// import { request } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'ProductionDetails',
  data () {
    return {
      dialogModify: false,
      ApplyingList: [
        {
          qxfl: '来料批不合格',
          qxz: '审批组',
          qxdm: 'SPZ110980988',
          qxms: '物料生产线产品批次不合格，未能达到审批标准',
          blsl: '112',
          sysl: '210',
          bll: '20%',
          wtlx: '一般异常'
        },
        {
          qxfl: '来料批不合格',
          qxz: '审批组',
          qxdm: 'SPZ110980988',
          qxms: '物料生产线产品批次不合格，未能达到审批标准',
          blsl: '112',
          sysl: '210',
          bll: '20%',
          wtlx: '一般异常'
        }
      ],
      bzData: {
        bzsm: ''
      },
      bzDataRules: {
        bzsm: [
          { required: true, message: '请填写备注内容', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
  },

  methods: {
    // 点击确认
    querySub () {
      this.dialogModify = true
    },
    // 点击弹框里的确认按钮
    confirmModify (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogModify = false
          // const obj = {
          //   bzsm: this.bzData.bzsm
          // }
          // request('/api/inquiry/discard', 'post', obj).then((res) => {
          //   if (res.code === '200') {
          //     this.$message({
          //       showClose: true,
          //       type: 'success',
          //       message: '操作成功成功'
          //     })
          //     this.dialogModify = false
          //     this.getIdData()
          //   } else {
          //     this.$message({
          //       showClose: true,
          //       message: res.msg,
          //       type: 'error'
          //     })
          //   }
          // })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 确认并通知供应商
    noticeSublier () {
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/elform.scss';
  .elrowflx :deep(.el-col){
    margin-bottom: 20px
  }
  .fbxj-table{
    .xunjia-news{
      color: #666;
      font-size: 16px;
      line-height: 50px;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      span{
        display: inline-block;
        width: 3px;
        background: #ccc;
        vertical-align: -4px;
        height: 20px;
        background: #4487fa;
        margin-right: 5px;
      }
    }
  }
  .feiqi{
    margin-left: 10px;
    font-size: 14px;
    border: 1px solid #F56C6C;
    padding: 0 5px 2px;
    color: #F56C6C;
  }
  .elrowflx{
    .elwidth{
      margin: 0 auto !important;
      max-width: 1200px;
    }
    .xjtit{
      float: left;
      margin-right: 20px;
      min-width: 120px;
      text-align: right;
      color: #666;
      font-size: 14px;
    }
    .xjcont{
      float: left;
      width: 50%;
      color: #666;
      font-size: 14px;
    }
    .mxtable{
      .el-table td{
        padding: 9px 0;
      }
      float: left;
      width: calc(100% - 140px);
    }
    .baojia{
      margin: 30px 0;
    }
    :deep(.el-table th){
      padding: 4px 0;
      background: #ededed;
    }
    :deep(.el-table__header-wrapper .el-table__header th .cell){
      line-height: 34px;
    }
    :deep(.el-table .el-table__empty-block){
      min-height: 42px;
    }
    :deep(.el-table .el-table__empty-block .el-table__empty-text){
      line-height: 42px;
      font-size: 14px;
    }
    .action-btn{
      margin: 10px 0 50px;
    }
    .xunjia-rizhi{
      margin-bottom: 60px;
      .el-collapse-item__header{
        font-size: 16px;
        color: #666;
        border-bottom: 1px solid #e4e4e4;
        &:before {
          content: " ";
          display: inline-block;
          width: 3px;
          height: 20px;
          background: #4487fa;
          vertical-align: text-top;
          margin-right: 5px;
      }
      }
      .el-collapse{
        border-top: none;
      }
      .el-collapse-item__content{
        color: #666;
        font-size: 14px;
        padding: 10px 30px;
        .czrz:before {
          content: " ";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #4487fa;
          vertical-align: 2px;
          margin-right: 6px;
        }
      }
    }
  }
  .detailsBtn{
    margin: 30px 0 40px;
    text-align: center;
  }

  .xbj-modify{
    :deep(.el-dialog__body){
      padding: 0;
    }
    :deep(.el-dialog__header){
      padding: 15px 20px 15px;
      border-bottom: 1px solid #e4e4e4;
    }
    :deep(.el-dialog__footer){
      padding: 15px 20px;
      border: 1px solid #e4e4e4;
    }
    :deep(.el-dialog){
      width: 500px !important;
      margin: 0 !important;
    }
    :deep(.el-checkbox){
      margin-bottom: 20px;
    }
    :deep(.el-pagination){
      position: initial;
      text-align: center;
      padding: 20px 0;
    }
    .el-overlay{
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .item1{
      margin: 0;
      padding: 20px 30px 40px;
    }
    .item2{
      border-top: 1px solid #e4e4e4;
      text-align: right;
      padding: 7px 30px 7px;
      margin-bottom: 0;
    }
    .bzCont{
      color: #666;
      font-size: 14px;
    }
  }

  .mainform-dilog{
    .dialogBox .el-overlay .el-dialog{
      width: 555px !important;
    }
    .dialogBox .el-overlay .el-dialog .el-dialog__body{
      padding: 20px 40px;
    }
  }
</style>
